<template>
  <v-card :loading="isLoading">
    <v-card-title>
      <h3 class="card-label">
        <div v-if="originalData.name">
          <small>Banka Hesabı</small><br />
          <span>{{ originalData.name }}</span>
        </div>
        <span v-else>Banka Hesabı</span>
      </h3>
      <v-col class="text-right p-0">
        <rs-action
          v-if="id && can('edit-bank-account')"
          @click="handleFirstBalanceFormClick"
        >
          Açılış Fişi Ekle
        </rs-action>

        <rs-action
          :href="templateDownloadTarget"
          target="_blank"
          v-if="originalData.id && can('edit-bank-account')"
        >
          Toplu Aktarım Şablonu
        </rs-action>

        <rs-action
          @click="handleImportClick"
          v-if="originalData.id && !disabled && can('edit-bank-account')"
          small
        >
          Toplu Aktarım
        </rs-action>

        <rs-file
          label="Dosya"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.oasis.opendocument.spreadsheet"
          :rules="[rules.required]"
          v-if="!disabled"
          @change="handleFileChange"
          class="d-none"
          id="importInput"
        />

        <rs-action
          :to="{ name: 'definitions.bank-accounts.edit', params: { id: id } }"
          v-if="can('edit-bank-account') && disabled"
          icon
          title="Düzenle"
        >
          <v-icon>mdi-pencil</v-icon>
        </rs-action>
      </v-col>

      <rs-action
        @click="$refs.confirmDelete.show('Silmek istediğinizden emin misiniz?')"
        v-if="
          $refs.confirmDelete &&
          id &&
          transactionCount === 0 &&
          can('delete-bank-account')
        "
        icon
        title="Sil"
      >
        <v-icon>mdi-delete-outline</v-icon>
      </rs-action>

      <rs-action
        @click="showActionLogs"
        v-if="id && can('edit-bank-account')"
        icon
        title="Düzenleme geçmişi"
      >
        <v-icon>mdi-information-variant</v-icon>
      </rs-action>

      <div class="d-block w-100 mb-2">
        <rs-return-to />
      </div>
    </v-card-title>
    <v-card-text>
      <div class="card-body">
        <v-tabs v-model="selectedTab">
          <v-tab to="#info" replace>Banka Hesap Bilgileri</v-tab>
          <v-tab
            to="#transactions"
            replace
            :disabled="!id"
            v-if="can('see-balance-activity')"
          >
            <v-badge
              color="deep-purple lighten-3"
              :content="transactionCount || '0'"
            >
              Hesap Hareketleri
            </v-badge>
          </v-tab>
          <v-tab to="#notes" replace :disabled="!id" v-if="can('see-note')">
            <v-badge color="deep-purple lighten-3" :content="noteCount || '0'">
              {{ $t("headers.notes_and_reminders") }}
            </v-badge>
          </v-tab>
          <v-tab
            to="#documents"
            replace
            :disabled="!id"
            v-if="can('see-document')"
          >
            <v-badge
              color="deep-purple lighten-3"
              :content="documentCount || '0'"
            >
              {{ $t("headers.documents") }}
            </v-badge>
          </v-tab>
        </v-tabs>

        <v-tabs-items touchless v-model="selectedTab">
          <v-tab-item value="info" class="pa-4">
            <v-form @submit.prevent="handleFormSubmit" ref="form">
              <v-row dense>
                <v-col cols="12" sm="6">
                  <rs-select-bank
                    v-model="formData.bank_id"
                    :rules="[rules.required]"
                    :label="$t('labels.bank')"
                    :readonly="disabled"
                    :filled="disabled"
                  />
                </v-col>

                <v-col sm="4">
                  <rs-select
                    :items="collectingTypeList"
                    :label="$t('labels.bank_income_expense_type')"
                    v-model="formData.collecting_type_id"
                    :readonly="disabled"
                    :filled="disabled"
                  />
                </v-col>

                <v-col cols="12" sm="6">
                  <rs-text-field
                    :label="$t('labels.account_short_name')"
                    :rules="[
                      rules.required,
                      rules.maxLength(formData.name, 50),
                    ]"
                    v-model="formData.name"
                    :readonly="disabled"
                    :filled="disabled"
                    hide-details="auto"
                  />
                </v-col>

                <v-col cols="12" sm="6">
                  <rs-text-field
                    label="Bankada Kayıtlı Hesap Adı"
                    :rules="[
                      rules.required,
                      rules.maxLength(formData.title, 100),
                    ]"
                    v-model="formData.title"
                    :readonly="disabled"
                    :filled="disabled"
                    hide-details="auto"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" sm="6">
                  <v-switch
                    v-model="formData.is_active"
                    :label="formData.is_active ? $t('active') : $t('passive')"
                    hide-details="auto"
                    :readonly="disabled"
                    :filled="disabled"
                  ></v-switch>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-switch
                    v-model="formData.can_receive_bills"
                    :label="$t('labels.can_receive_bills')"
                    hide-details="auto"
                    :readonly="disabled"
                    :filled="disabled"
                  ></v-switch>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-switch
                    v-model="formData.can_collect_money"
                    :label="$t('labels.can_collect_money')"
                    hide-details="auto"
                    :readonly="disabled"
                    :filled="disabled"
                  ></v-switch>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-switch
                    v-model="formData.can_used_for_sms"
                    :label="$t('labels.can_used_for_sms')"
                    hide-details="auto"
                    :readonly="disabled"
                    :filled="disabled"
                  ></v-switch>
                </v-col>

                <v-col sm="12">
                  <v-switch
                    v-model="formData.residents_can_see"
                    :label="$t('labels.residents_can_see')"
                    hide-details="auto"
                    :readonly="disabled"
                    :filled="disabled"
                  ></v-switch>
                </v-col>
              </v-row>
              <v-divider class="my-10" />
              <v-row dense>
                <v-col cols="12" class="pb-0">
                  <h3 class="mb-0">Hesap Bilgileri</h3>
                </v-col>
                <v-col sm="6" lg="4">
                  <rs-select-bank-account-type
                    v-model="formData.account_type_id"
                    :rules="[rules.required]"
                    :label="$t('labels.account_type')"
                    :readonly="disabled"
                    :filled="disabled"
                    @change="handleAccountTypeChange"
                  />
                </v-col>
                <v-col sm="6" lg="4">
                  <rs-text-field
                    :label="$t('labels.bank_client_code')"
                    :rules="[
                      isPosAccount || rules.required,
                      rules.maxLength(formData.bank_client_code, 50),
                    ]"
                    v-model="formData.bank_client_code"
                    :readonly="disabled || isPosAccount"
                    :filled="disabled || isPosAccount"
                  />
                </v-col>
                <v-col sm="6" lg="4">
                  <rs-text-field
                    :label="$t('labels.bank_branch_code')"
                    :rules="[
                      isPosAccount || rules.required,
                      rules.maxLength(formData.bank_branch_code, 50),
                    ]"
                    v-model="formData.bank_branch_code"
                    :readonly="disabled || isPosAccount"
                    :filled="disabled || isPosAccount"
                  />
                </v-col>
                <v-col sm="6" lg="4">
                  <rs-text-field
                    :label="$t('labels.bank_institution_code')"
                    :rules="[
                      rules.maxLength(formData.bank_institution_code, 20),
                    ]"
                    v-model="formData.bank_institution_code"
                    :readonly="disabled || isPosAccount"
                    :filled="disabled || isPosAccount"
                  />
                </v-col>
                <v-col sm="6" lg="4">
                  <rs-text-field
                    :label="$t('labels.bank_account_number')"
                    :rules="[
                      isPosAccount || rules.required,
                      rules.maxLength(formData.bank_account_number, 25),
                    ]"
                    v-model="formData.bank_account_number"
                    :readonly="disabled || isPosAccount"
                    :filled="disabled || isPosAccount"
                  />
                </v-col>
                <v-col sm="6" lg="4">
                  <rs-text-field
                    :label="$t('labels.iban')"
                    :rules="[isPosAccount || rules.required, rules.iban]"
                    v-model="formData.bank_iban"
                    :readonly="disabled || isPosAccount"
                    :filled="disabled || isPosAccount"
                    v-mask="'TR ## #### #### #### #### #### ##'"
                  />
                </v-col>
              </v-row>
              <v-divider class="my-10" />
              <v-row dense>
                <v-col cols="12" class="pb-0">
                  <h3 class="mb-0">Yetkili Kişi</h3>
                </v-col>
                <v-col cols="12" sm="6">
                  <rs-text-field
                    :label="$t('labels.concern_name')"
                    :rules="[rules.maxLength(formData.concern_name, 100)]"
                    v-model="formData.concern_name"
                    :readonly="disabled"
                    :filled="disabled"
                  />
                </v-col>

                <v-col cols="12" sm="6">
                  <rs-text-field
                    :label="$t('labels.email')"
                    :rules="[rules.maxLength(formData.concern_email, 100)]"
                    v-model="formData.concern_email"
                    type="email"
                    :readonly="disabled"
                    :filled="disabled"
                  />
                </v-col>

                <v-col cols="12" sm="6">
                  <rs-text-field
                    :label="$t('labels.concern_position')"
                    :rules="[rules.maxLength(formData.concern_position, 100)]"
                    v-model="formData.concern_position"
                    :readonly="disabled"
                    :filled="disabled"
                  />
                </v-col>

                <v-col cols="12" sm="6">
                  <rs-tel-field
                    :label="$t('labels.phone')"
                    v-model="formData.concern_phone"
                    :disabled="disabled"
                    :rules="[
                      formData.concern_phone != ''
                        ? rules.minLength(formData.concern_phone, 7)
                        : null,
                      rules.numbersOnlyWithParam,
                    ]"
                  />
                </v-col>
              </v-row>
              <v-divider class="my-10" />
              <v-row v-if="id && totals">
                <v-col cols="12">
                  <h3>Durum</h3>
                </v-col>
                <v-col sm="4">
                  <rs-money-field
                    :label="$t('labels.debt')"
                    :value="totals.bank_safe_debt"
                    :disabled="true"
                  />
                </v-col>

                <v-col sm="4">
                  <rs-money-field
                    :label="$t('labels.due')"
                    :value="totals.bank_safe_due"
                    :disabled="true"
                  />
                </v-col>

                <v-col sm="4">
                  <rs-money-field
                    :label="$t('labels.balance')"
                    :value="balance"
                    :disabled="true"
                  />
                </v-col>
              </v-row>

              <rs-form-buttons
                :is-loading="isLoading"
                @cancel="handleCancelClick"
                @submit="handleFormSubmit"
                v-if="!disabled"
              />
            </v-form>
          </v-tab-item>
          <v-tab-item
            value="transactions"
            class="pa-4"
            :eager="id"
            v-if="can('see-balance-activity')"
          >
            <BankBalanceActivityList
              :bank-account-id="id"
              v-if="id"
              :item-count.sync="transactionCount"
              ref="balanceActivityList"
              :totals.sync="totals"
            />
          </v-tab-item>
          <v-tab-item value="notes" class="pa-4" :eager="id">
            <!-- TODO: the notes should be separate based on "mode" -->
            <NoteList
              :allow-add="true"
              :extra-params="{
                bank_account_id: id,
                cluster_company_id: 1,
              }"
              v-if="id && can('see-note')"
              :item-count.sync="noteCount"
              :form-subtitle="originalData.name"
            />
          </v-tab-item>
          <v-tab-item value="documents" class="pa-4" :eager="id">
            <!-- TODO: the notes should be separate based on "mode" -->
            <DocumentList
              :allow-add="true"
              :extra-params="{
                bank_account_id: id,
                cluster_company_id: 1,
              }"
              v-if="id && can('see-note')"
              :item-count.sync="documentCount"
            />
          </v-tab-item>
        </v-tabs-items>
      </div>

      <FirstBalanceForm
        ref="firstBalanceForm"
        @saved="$refs.balanceActivityList.loadList()"
      />

      <rs-confirm
        ref="confirmDelete"
        :loading="isLoading"
        @confirmed="handleDeleteClick"
        @cancelled="$refs.confirmDelete.hide()"
      />
      <ActionLogList ref="actionLogList" />
    </v-card-text>
  </v-card>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";

import { hasForm, hasPermissions } from "@/view/mixins";
import ActionLogList from "@/view/content/action-logs/ActionLogList";
import FirstBalanceForm from "@/view/content/safes/FirstBalanceForm";
import NoteList from "@/view/content/Notes/NoteList";
import DocumentList from "@/view/content/documents/DocumentList";
import BankBalanceActivityList from "./BankBalanceActivityList.vue";

export default {
  name: "BankAccountForm",
  mixins: [hasForm, hasPermissions],
  components: {
    ActionLogList,
    DocumentList,
    FirstBalanceForm,
    NoteList,
    BankBalanceActivityList,
  },
  props: {
    id: {
      type: Number,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    id() {
      this.load();
    },
    clusterId: {
      handler() {
        if (this.id) {
          return;
        }

        this.$nextTick(() => {
          if (this.clusterId) {
            this.formData.cluster_id = this.clusterId;
          } else {
            this.formData.cluster_id = null;
          }
        });
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["clusterId", "collectingTypeList", "companyId"]),
    balance() {
      if (this.totals) {
        const total =
          (this.totals.bank_safe_debt * 100 - this.totals.bank_safe_due * 100) /
          100;

        return total;
      }

      return null;
    },
    templateDownloadTarget() {
      return Vue.axios.defaults.baseURL + "templates/import/bank-activities";
    },
    isPosAccount() {
      return this.formData.account_type_id === 4;
    },
  },
  data() {
    return {
      selectedTab: "info",
      formData: {
        cluster_id: null,
        bank_id: null,
        name: null,
        title: null,
        collecting_type_id: null,
        is_active: true,
        can_receive_bills: false,
        can_collect_money: false,
        residents_can_see: false,
        can_used_for_sms: false,
        account_type_id: 1,
        bank_client_code: null,
        bank_branch_code: null,
        bank_institution_code: null,
        bank_account_number: null,
        bank_iban: null,
        concern_name: null,
        concern_phone: null,
        concern_email: null,
        concern_position: null,
      },
      transactionCount: null,
      totals: null,
    };
  },
  methods: {
    ...mapActions(["updateClusterId", "updateBankActivityCount"]),
    /**
     * Retrieves item data
     * @return {Promise} Resolves if data is retrieved
     */
    load() {
      this.isLoading = true;

      return this.$api
        .query(`bank-accounts/${this.id}`)
        .then((response) => {
          this.loadData(response);
          this.updateClusterId(this.originalData.cluster_id);
          this.handleAccountTypeChange(this.originalData.account_type_id);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;
      const formData = Object.assign({}, this.formData);

      if (formData.concern_phone) {
        formData.concern_phone = this.formData.concern_phone.replace(
          /[^0-9+]/g,
          ""
        );
      }

      if (formData.bank_iban) {
        formData.bank_iban = this.formData.bank_iban.replace(/[^0-9TR+]/g, "");
      }

      if (this.id) {
        this.$api
          .put(`bank-accounts/${this.id}`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            if (event && event.closeOnSave) {
              this.$router.back();
              return;
            }

            this.loadData(response);
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.$api
          .post(`bank-accounts`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            if (event && event.closeOnSave) {
              this.$router.back();
              return;
            }

            this.$router.replace({
              name: "definitions.bank-accounts.edit",
              params: { id: response.data.data.id },
            });
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    handleCancelClick() {
      this.$router.back();
    },
    handleDeleteClick() {
      if (this.isLoading) {
        return false;
      }

      this.isLoading = true;

      this.$api
        .delete(`bank-accounts/${this.id}`)
        .then(() => {
          this.$toast.success("Silindi");
          this.$router.replace({
            name: "definitions.bank-accounts.list",
          });
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.$refs.confirmDelete.hide();
          this.isLoading = false;
        });
    },
    handleFirstBalanceFormClick() {
      this.$refs.firstBalanceForm.show(null, {
        bankAccount: this.originalData,
      });
    },
    showActionLogs() {
      this.$refs.actionLogList.show("App\\Models\\BankAccount", this.id);
    },
    handleImportClick() {
      document.querySelector("input#importInput").value = null;
      document.querySelector("input#importInput").click();
    },
    handleFileChange(file) {
      if (!file) {
        return;
      }

      const allowedMimes = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.oasis.opendocument.spreadsheet",
      ];

      if (allowedMimes.indexOf(file.type) === -1) {
        this.$toast.error("Sadece XLSX veya ODS yükleyebilirsiniz.");
        return;
      }

      this.isLoading = true;
      const data = new FormData();
      data.append("file", file);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      this.$api
        .post(`bank-accounts/${this.id}/import-activities`, data, config)
        .then((response) => {
          this.$toast.success(
            `${response.data.data.created} adet işlem kaydedildi`
          );

          if (!this.companyId) {
            return;
          }

          const params = {
            company_id: this.companyId,
            cluster: [this.originalData.cluster_id],
            list_type: "open",
            per_page: 1,
          };

          this.$api
            .query("income/bank-activities", { params })
            .then((response) => {
              if (response.data && response.data.meta) {
                this.updateBankActivityCount(response.data.meta.total);
              }
            })
            .catch(() => false);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleAccountTypeChange(newValue) {
      if (newValue === 4) {
        this.formData.bank_account_number = undefined;
        this.formData.bank_iban = undefined;
        this.formData.bank_branch_code = undefined;
        this.formData.bank_client_code = undefined;
        this.formData.bank_institution_code = undefined;
      }
    },
  },
  mounted() {
    if (this.id) {
      this.load();
    }
  },
};
</script>

<style lang="scss">
.v-input--checkbox {
  label {
    margin-bottom: 0;
  }
}
</style>
